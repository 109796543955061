import React from "react";

// COMPONENTS
import PageBanner from "../../components/general/PageBanner";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();

  const handleSignUpRedirect = () => {
    navigate("/signup"); // Redirect to the registration page
  };

  return (
    <>
      <PageBanner
        heading="Account Login"
        text="Log in to access your account"
      ></PageBanner>

      <div className="text-center mt-4">
        <p>Don't have an account yet?</p>
        <button className="btn btn-primary" onClick={handleSignUpRedirect}>
          Sign Up
        </button>
      </div>

      <section className="pb-5 page-first-section">
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div className="card p-md-4">
                <div className="card-body">
                  <header className="text-center mb-4">
                    <h4 className="mb-0">Log in</h4>
                    <p className="text-muted">Enter your credentials</p>
                  </header>
                  <LoginForm />
                  {/* Sign Up Section */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginPage;
