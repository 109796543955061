import React from "react";

// COMPONENTS
import PageBanner from "../../components/general/PageBanner";
import SignUpForm from "./SignupForm";

function RegisterPage() {
  return (
    <>
      <PageBanner
        heading="Create an account"
        text="Register for an account to access features"
      ></PageBanner>

      <section className="pb-5 page-first-section">
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div className="card p-md-4">
                <div className="card-body">
                  <header className="text-center mb-4">
                    <h4 className="mb-0">Fill the Form Below</h4>
                    <p className="text-muted">To create an account</p>
                  </header>
                  <SignUpForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterPage;
