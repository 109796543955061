/* -----------------------------------------------------------------------------------------
    THSI FILE CONTAINS THE IMPORTANT WEB3/UI CONFIGURATIONS FOR THE APP
----------------------------------------------------------------------------------------- */

export const appSettings = {
    /* APP LOGO - LOGO FOR THE DARK VERSION  */
    logo: '/logo.svg',

    /* APP MAIN LOGO WIDTH - CHANGE IT ACCORDING TO YOUR OWN LOGO */
    logoWidth: '100',

    /* APP BRAND NAME - USED IN ALL PAGES & META TAGE - CHANGE TO YOUR OWN BRAND NAME*/
    brandName: 'Fundik',

    /* APP BRAND DESCRIPTION - USED IN HOME PAGE & META TAGS - PUT A SENTENCE THAT DESCRIBE YOUR APP*/
    brandDescription: 'Decentralized Crowdfunding app',

    /* APP AUTHOR - CHANGE IT IF YOU HAVE A LICENSE KEY OR PURCHASE CODE */
    appAuthor: 'Web3 Monkeys',

    /* COPYRIGHTS BRAND NAME */
    copyrightsBrand: 'Web3 Monkeys',

    /* TEXT IDENTIFIER FOR THE NETWORK WHERE THE APP IS UP AND RUNNING, YOU CAN WRITE WHATEVER YOU WANT */
    activeNetworkName: 'Celo Alfajores Network',

    /* COPYRIGHTS LINK - REPLACE IT IF YOU HAVE A LICENSE KEY OR A PURCHASE CODE */
    copyrightsLink: 'https://web3monkeys.com',

    /* THE NETWORK RPC URL WHERE YOUR CONTRACTS ARE DEPOLYED ON, CHECK THE EXACT RPC URL FROM HERE https://chainlist.org */
    rpcUrl: 'https://alfajores-forno.celo-testnet.org',

    /* THE CHAIN ID of NETWORK WHERE YOUR CONTRACTS ARE DEPOLYED ON, GET IT FROM HERE https://chainlist.org */
    networkId: 44787,

    /* THE BLOCK SCAN EXPLORER WHRE YOU CAN TRACK THE TRANSACTIONS */
    blockExplorerUrl: 'https://alfajores-blockscout.celo-testnet.org',

    /* THE CURRENT APP CURRENCY THAT YOUR APP WILL APPEAR BESIDES EVERY PRICE */
    currency: 'CELO',

    /* THE NATIVE CURRENCY THAT YOUR APP WILL USE FOR GAS FEES */
    nativeCurrency: 'CELO',

    /* IPFS PROJECT ID */
    IPFSProjectID: '',

    /* IPFS PRJECT SECRET KEY */
    IPFSSecret: '',

    /* IPFS DEDICATED GATEWAY NAME */
    IPFSGatewaySubdomain: 'elysium',

    /* ENDPOINT FOR ASK FOR A FEATURE FORM */
    newsletterFormId: 'moqzjqva',

    /* WALLET CONNECT PROJECT ID */
    wcProjectId: '',

    /* MAX CAMPAIGN DURATION IN DAYS */
    maxDuration: 10,
};
