import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { login, getProfile } from "../../api";
import { AuthContext } from "../../context/authContext";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA

function LoginForm() {
  const [submit, setSubmit] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // Add state for captcha
  const navigate = useNavigate();
  const { login: authenticate } = useContext(AuthContext);

  const {
    formState: { errors },
  } = useForm();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const response = await getProfile();
        if (response.data) {
          navigate("/profile");
          window.location.reload(); // Redirect to profile if already logged in
        }
      } catch (error) {
        // Handle the case where the profile check fails
        console.error("Error checking logged in status:", error);
      }
    };

    checkLoggedIn();
  }, [navigate]);

  const validator = () => {
    const { username, password } = formData;
    if (password.length < 7) {
      toast.error("Password must be at least 7 characters long", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return false;
    }
    if (username.length < 5) {
      toast.error("Username must be at least 5 characters long", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return false;
    }
    if (!captchaValue) {
      toast.error("Please complete the CAPTCHA", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set the captcha value when completed
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validator()) {
      // Clear password fields if there is a validation error
      setFormData({ ...formData, username: "", password: "" });
      return;
    }
    setSubmit(true);

    try {
      const response = await login({
        ...formData,
        captchaToken: captchaValue,
      });
      authenticate(response.data.token);
      toast.success("Logged in successfully!", {
        position: "bottom-right",
        autoClose: 3000,
      });
      navigate("/profile"); // Redirect to profile page
    } catch (error) {
      toast.error("Incorrect username or password", {
        position: "bottom-right",
        autoClose: 3000,
      });
      // Clear the form fields
      setSubmit(false);
    }
  };

  return (
    <>
      {!submit && (
        <form onSubmit={handleFormSubmit}>
          <div className="row g-4">
            {/* Username */}
            <div className="col-lg-6">
              <label className="form-label" htmlFor="username">
                Username
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.username ? "is-invalid" : ""
                }`}
                onChange={handleChange}
                id="username"
                placeholder="Enter your username"
                name="username"
                value={formData.username}
              />
            </div>

            {/* Password */}
            <div className="col-lg-6">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <input
                onChange={handleChange}
                type="password"
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
                id="password"
                placeholder="Enter your password"
                name="password"
                value={formData.password}
              />
            </div>

            {/* CAPTCHA */}
            <div className="col-12">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Replace with your actual Site Key
                onChange={handleCaptchaChange}
              />
            </div>

            {/* Submit Button */}
            <div className="col-12">
              <button className="btn btn-primary" type="submit">
                Proceed
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default LoginForm;
