import React, { useEffect, useState } from "react";
import { getTags, addTag, deleteTag, getProfile } from "../../api";
import PageBanner from "../../components/general/PageBanner";
import AllCampaigns from "./AllCampaigns";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection

function AdminPanel() {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(null); // State to store admin status
  const navigate = useNavigate(); // Hook for redirection

  // Fetch user profile and tags on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResponse = await getProfile(); // Fetch user profile
        if (profileResponse.data.admin) {
          setIsAdmin(true); // Set admin status
          // Fetch tags if user is an admin
          const tagsResponse = await getTags();
          setTags(tagsResponse.data); // Update state with fetched tags
        } else {
          setIsAdmin(false); // Set non-admin status
          navigate("/"); // Redirect to Page Not Found
        }
      } catch (error) {
        console.error("Failed to fetch profile or tags:", error);
        setIsAdmin(false); // Handle errors and set non-admin status
        navigate("/"); // Redirect to Page Not Found
      }
    };

    fetchData(); // Call the fetch function
  }, [navigate]); // Run effect when component mounts

  // Handle form submission for adding a new tag
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newTag.trim()) {
      setError("Tag name is required.");
      return;
    }

    try {
      await addTag({ name: newTag.trim() }); // Add new tag
      setNewTag(""); // Clear input
      setSuccessMessage("Tag added successfully!");
      setError(""); // Clear any previous error
      // Refetch tags to update the list
      const response = await getTags();
      setTags(response.data);
    } catch (error) {
      setError("Failed to add tag.");
      console.error("Failed to add tag:", error);
    }
  };

  // Handle deleting a tag
  const handleDeleteTag = async (tagName) => {
    try {
      await deleteTag(tagName); // Delete tag by name
      // Refetch tags to update the list
      const response = await getTags();
      setTags(response.data);
      setSuccessMessage(`Tag "${tagName}" deleted successfully!`);
      setError(""); // Clear any previous error
    } catch (error) {
      setError("Failed to delete tag.");
      console.error("Failed to delete tag:", error);
    }
  };

  if (isAdmin === null) {
    return <p>Loading...</p>; // Show loading state while checking admin status
  }

  if (isAdmin === false) {
    return <p>Page Not Found</p>; // Render a Page Not Found message for non-admin users
  }

  return (
    <>
      <PageBanner heading="Admin Panel" text="Admin Controls" />
      <div
        className="container mt-4"
        style={{
          backgroundColor: "#333333",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <h1 className="mb-4 text-light">Manage Tags</h1>

        {/* Card for displaying current tags */}
        <div
          className="card mb-4"
          style={{ borderRadius: "8px", backgroundColor: "#444444" }}
        >
          <div
            className="card-header"
            style={{
              backgroundColor: "#333333",
              color: "#ffffff",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <h2 className="mb-0">Current Tags</h2>
          </div>
          <div
            className="card-body"
            style={{ backgroundColor: "#333333", color: "#333333" }}
          >
            <ul className="list-group">
              {tags.length > 0 ? (
                tags.map((tag) => (
                  <li
                    key={tag}
                    className="list-group-item d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: "#444444",
                      border: "none",
                      color: "#ffffff",
                    }}
                  >
                    {tag}
                    <button
                      onClick={() => handleDeleteTag(tag)}
                      className="btn btn-danger btn-sm"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#ff4d4d",
                        borderColor: "#ff4d4d",
                        color: "#ffffff",
                      }}
                    >
                      Delete
                    </button>
                  </li>
                ))
              ) : (
                <p className="text-muted">No tags available.</p>
              )}
            </ul>
          </div>
        </div>

        {/* Card for form to add new tag */}
        <div
          className="card"
          style={{ borderRadius: "8px", backgroundColor: "#333333" }}
        >
          <div
            className="card-header"
            style={{
              backgroundColor: "#333333",
              color: "#ffffff",
              borderRadius: "8px 8px 8px 8px",
            }}
          >
            <h2 className="mb-0">Add New Tag</h2>
          </div>
          <div
            className="card-body"
            style={{ backgroundColor: "#333333", color: "#ffffff" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="newTag"
                  className="form-label"
                  style={{ color: "#ffffff" }}
                >
                  Tag Name
                </label>
                <input
                  type="text"
                  id="newTag"
                  className="form-control"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  style={{
                    backgroundColor: "#555555",
                    color: "#ffffff",
                    borderColor: "#666666",
                  }}
                />
              </div>
              {error && <p className="text-danger">{error}</p>}
              {successMessage && (
                <p className="text-success">{successMessage}</p>
              )}
              <button type="submit" className="btn btn-primary">
                Add Tag
              </button>
            </form>
          </div>
        </div>
      </div>
      <AllCampaigns />
    </>
  );
}

export default AdminPanel;
