import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
// import { categories } from "../../helpers/constants";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

// API
import { createCampaign, getTags } from "../../api";

// HOOKS
import useApp from "../../hooks/useApp";

function CampaignForm() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch tags when component mounts
    const fetchTags = async () => {
      try {
        const tags = await getTags(); // Fetch tags from API
        const formattedTags = tags.data.map((tag) => ({
          value: tag,
          label: tag,
        })); // Format tags for categories
        setCategories(formattedTags); // Update state
      } catch (error) {
        console.error("Failed to fetch tags:", error);
      }
    };

    fetchTags(); // Call the fetch function
  }, []);

  const navigate = useNavigate();
  const { setTransactionLoading } = useApp();
  const [submit, setSubmit] = useState(false);
  const [image, setImage] = useState(null); // Add state for the image

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* --------------------------------------------- 
          HANDLE REGISTER FORM SUBMIT
    --------------------------------------------- */
  async function handleFormSubmit(data) {
    try {
      setTransactionLoading(true);
      setSubmit(true);

      const formData = new FormData(); // Use FormData for image upload

      formData.append("goalAmount", data.goal); // Matching backend goalAmount
      formData.append("title", data.title);
      formData.append("description", data.description);

      // Ensure category is a string
      const category = data.category ? data.category.value : "";
      formData.append("tags", category);

      formData.append("participantSlots", data.participantSlots); // Add participantSlots
      formData.append("image", image);

      // Make the API request
      const response = await createCampaign(formData);

      // Check if the response status is 201
      if (response.status === 201) {
        toast.success("Campaign created successfully!");
        navigate(`/campaigns/${response.data._id}`); // Redirect to campaigns page
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        throw new Error("Failed to create campaign");
      }
    } catch (error) {
      setSubmit(false);

      // Check for error code and provide custom messages based on status code
      if (error.response) {
        switch (error.response.status) {
          case 400:
            toast.error(
              "Insufficient funds. Please add more funds to proceed."
            );
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            break;
          case 500:
            toast.error(
              "Incorrect image format. Only PNG and JPG are allowed."
            );
            break;
          default:
            toast.error("An unexpected error occurred. Please try again.");
            setTimeout(() => {
              window.location.reload();
            }, 3000);
        }
      } else if (error.message) {
        toast.error(error.message || "Something went wrong");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("An unknown error occurred");
        // wait 2 seconds then reload the page

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } finally {
      setTransactionLoading(false);
    }
  }

  /* --------------------------------------------- 
          HANDLE IMAGE UPLOAD
    --------------------------------------------- */
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  return (
    <>
      {!submit && (
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <div className="row g-4">
            {/* TITLE */}
            <div className="col-lg-12">
              <label className="form-label" htmlFor="title">
                Title
              </label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                id="title"
                placeholder="Enter campaign title name"
                name="title"
                maxLength="29" // Limit input to 40 characters
                {...register("title", {
                  required: {
                    value: true,
                    message: "Enter campaign title name",
                  },
                  maxLength: {
                    value: 40,
                    message: "Title must be at most 40 characters",
                  },
                })}
              />
              {errors.title && (
                <span className="invalid-feedback">
                  {errors.title?.message}
                </span>
              )}
            </div>

            {/* DESCRIPTION */}
            <div className="col-lg-12">
              <label className="form-label" htmlFor="description">
                Description
              </label>
              <textarea
                rows="7"
                className={`form-control ${
                  errors.description ? "is-invalid" : ""
                }`}
                id="description"
                placeholder="Enter the campaign description"
                name="description"
                {...register("description", {
                  required: {
                    value: true,
                    message: "Enter the campaign description",
                  },
                  minLength: {
                    value: 40,
                    message:
                      "Campaign description must be more than 40 characters",
                  },
                })}
              ></textarea>
              {errors.description && (
                <span className="invalid-feedback">
                  {errors.description?.message}
                </span>
              )}
            </div>

            {/* GOAL */}
            <div className="col-lg-6">
              <label className="form-label" htmlFor="goal">
                Goal - USD
              </label>
              <input
                type="number"
                step="1"
                className={`form-control ${errors.goal ? "is-invalid" : ""}`}
                id="goal"
                placeholder="Enter your campaign goal"
                name="goal"
                {...register("goal", {
                  required: {
                    value: true,
                    message: "Enter your campaign goal",
                  },
                  min: {
                    value: 100,
                    message: "Goal must be at least 100 USD",
                  },
                })}
              />
              {errors.goal && (
                <span className="invalid-feedback">{errors.goal?.message}</span>
              )}
            </div>

            {/* CATEGORY */}
            <div className="col-lg-6">
              <label className="form-label" htmlFor="category">
                Category
              </label>
              <Controller
                name="category"
                control={control}
                rules={{ required: "Please select a category" }}
                render={({ field }) => (
                  <Select
                    options={categories}
                    id="category"
                    className={`border-0 shadow-sm ${
                      errors.category ? "is-invalid" : ""
                    }`}
                    classNamePrefix="select"
                    placeholder="Select"
                    isSearchable={true}
                    {...field}
                  />
                )}
              />
              {errors.category && (
                <span className="invalid-feedback">
                  {errors.category?.message}
                </span>
              )}
            </div>

            {/* PARTICIPANT SLOTS */}
            <div className="col-lg-6">
              <label className="form-label" htmlFor="participantSlots">
                Participant Slots
              </label>
              <input
                type="number"
                className={`form-control ${
                  errors.participantSlots ? "is-invalid" : ""
                }`}
                id="participantSlots"
                placeholder="Enter the number of participant slots"
                name="participantSlots"
                {...register("participantSlots", {
                  required: {
                    value: true,
                    message: "Enter the number of participant slots",
                  },
                  min: {
                    value: 2,
                    message: "There must be at least 2 participant slot",
                  },
                })}
              />
              {errors.participantSlots && (
                <span className="invalid-feedback">
                  {errors.participantSlots?.message}
                </span>
              )}
            </div>

            {/* IMAGE UPLOAD */}
            <div className="col-lg-12">
              <label className="form-label" htmlFor="image">
                Campaign Image
              </label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept="image/png, image/jpeg"
                onChange={handleImageChange} // Handle image upload
              />
            </div>

            {/* SUBMIT */}
            <div className="col-12">
              <button className="btn btn-primary" type="submit">
                Create Campaign
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default CampaignForm;
