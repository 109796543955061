import React from "react";

// COMPONENTS
import PageBanner from "../../components/general/PageBanner";
import Profile from "./Profile";

function ProfilePage() {
  return (
    <>
      <PageBanner heading="User Profile" text="Account"></PageBanner>

      <Profile />
    </>
  );
}

export default ProfilePage;
