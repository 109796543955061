import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import {
  getProfile,
  getCampaigns,
  updatePassword,
  getRatings,
  rateUser,
  addTransaction,
  getTransactions, // Import getTransactions function
} from "../../api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import FundraiseCard from "../../components/general/FundraiseCard";

function Profile() {
  const navigate = useNavigate();
  const { logout, userId } = useContext(AuthContext);

  const [user, setUser] = useState(null);
  const [contributionsWithCampaigns, setContributionsWithCampaigns] = useState(
    []
  );
  const [myCampaigns, setMyCampaigns] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [submittingRating, setSubmittingRating] = useState(null);
  const [transactions, setTransactions] = useState([]); // State to hold transactions
  const [loadingTransactions, setLoadingTransactions] = useState(true); // Loading state for transactions

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        const userData = response.data;

        const campaignResponse = await getCampaigns();
        const campaigns = campaignResponse.data;

        const updatedContributions = userData.contributions
          .map((contribution) => {
            const campaign = campaigns.find(
              (campaign) => campaign._id === contribution.campaign
            );
            return campaign ? campaign : null;
          })
          .filter((campaign) => campaign !== null);

        const userCreatedCampaigns = campaigns.filter(
          (campaign) => campaign.creator._id === userData._id
        );

        const ratingsResponse = await getRatings(userId);
        const userRatings = ratingsResponse.data;

        setUser(userData);
        setContributionsWithCampaigns(updatedContributions);
        setMyCampaigns(userCreatedCampaigns);
        setRatings(userRatings);
      } catch (error) {
        console.error("Error fetching profile:", error);
        toast.error("Failed to fetch profile data. Please try again.", {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    };

    const fetchTransactions = async () => {
      try {
        setLoadingTransactions(true); // Start loading
        const transactionResponse = await getTransactions(); // Fetch transactions
        setTransactions(transactionResponse.data); // Set transactions to state
      } catch (error) {
        console.error("Error fetching transactions:", error);
        toast.error("Failed to fetch transactions. Please try again.");
      } finally {
        setLoadingTransactions(false); // Stop loading
      }
    };

    fetchProfile();
    fetchTransactions(); // Fetch transactions when component mounts
  }, [userId]);

  const handleLogout = () => {
    logout();
    toast.success("Logged out successfully!", {
      position: "bottom-right",
      autoClose: 3000,
    });
    navigate("/login");
  };

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handlePasswordUpdate = async () => {
    if (newPassword.length < 7) {
      setPasswordError("Password must be at least 7 characters long.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    try {
      await updatePassword({ password: newPassword });

      toast.success("Password updated successfully!");
      setNewPassword("");
      setConfirmPassword("");
      setShowPasswordForm(false);

      setTimeout(() => {
        handleLogout();
      }, 3000);
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Failed to update password. Please try again.");
    }
  };

  const handleRatingSubmit = async (ratingId, answer) => {
    try {
      setSubmittingRating(ratingId);
      await rateUser({ campaignId: ratingId, answer });

      toast.success("Rating submitted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      setRatings((prevRatings) =>
        prevRatings.filter((rating) => rating._id !== ratingId)
      );
      setSubmittingRating(null);
    } catch (error) {
      console.error("Error submitting rating:", error);
      toast.error("Failed to submit rating. Please try again.");
      setSubmittingRating(null);
    }
  };

  const [showAddBalanceForm, setShowAddBalanceForm] = useState(false);
  const [balanceAmount, setBalanceAmount] = useState("");
  const [balanceError, setBalanceError] = useState("");

  const handleAddBalance = async () => {
    if (!balanceAmount || isNaN(balanceAmount) || Number(balanceAmount) <= 0) {
      setBalanceError("Please enter a valid amount.");
      return;
    }

    const amount = parseFloat(balanceAmount).toFixed(2);
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      toast.error("Invalid amount. Please enter a valid number.");
      return;
    }

    try {
      const response = await addTransaction({ oldAmount: Number(amount) });

      const { amount: transactionAmount, address } = response.data;

      const paymentUrl = `https://trocador.app/en/anonpay?ticker_to=usdt&network_to=TRC20&address=${address}&amount=${transactionAmount}`;
      window.location.href = paymentUrl;
    } catch (error) {
      console.error("Error adding balance:", error);
      toast.error("Failed to add balance. Please try again.");
      setBalanceError("An error occurred while adding balance.");
    }
  };

  return (
    <>
      {user && (
        <section className="pb-5">
          <div className="container">
            <div className="row justify-content-center gy-4">
              <div className="col-12 text-center mb-4">
                {user && user.status === "inactive" ? (
                  <div
                    className="alert alert-warning"
                    style={{ backgroundColor: "#333333", color: "#ffffff" }}
                  >
                    <h1>Your account is currently inactive.</h1>
                    <p>
                      Please complete pending actions to reactivate your
                      account.
                    </p>
                  </div>
                ) : (
                  <div className="profile-info">
                    <h1>Welcome, {user.username}</h1>
                    <p>Balance: ${user.balance.toFixed(2)}</p>
                  </div>
                )}
              </div>

              <div className="container">
                <div className="row justify-content-center mb-5">
                  {user?.admin && (
                    <div className="col-6 col-md-3 text-center mb-2">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => navigate("/admin")}
                        style={{ maxWidth: "200px" }}
                      >
                        Admin Panel
                      </button>
                    </div>
                  )}

                  <div className="col-6 col-md-3 text-center mb-2">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => setShowPasswordForm(!showPasswordForm)}
                      style={{ maxWidth: "200px" }}
                    >
                      {showPasswordForm ? "Cancel" : "Change Password"}
                    </button>
                  </div>
                  <div className="col-6 col-md-3 text-center mb-2">
                    <button
                      className="btn btn-danger w-100"
                      onClick={handleLogout}
                      style={{ maxWidth: "200px" }}
                    >
                      Logout
                    </button>
                  </div>
                  <div className="col-6 col-md-3 text-center mb-2">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => setShowAddBalanceForm(!showAddBalanceForm)}
                      style={{ maxWidth: "200px" }}
                    >
                      {showAddBalanceForm ? "Cancel" : "Add Balance"}
                    </button>
                  </div>
                </div>

                {showPasswordForm && (
                  <div className="row justify-content-center">
                    <div className="col-12 text-center mb-4">
                      <div className="password-form mt-3">
                        <div
                          className="input-group mb-2"
                          style={{ maxWidth: "300px", margin: "0 auto" }}
                        >
                          <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="form-control"
                            style={{ padding: "0.5rem", fontSize: "0.875rem" }}
                          />
                        </div>
                        <div
                          className="input-group mb-2"
                          style={{ maxWidth: "300px", margin: "0 auto" }}
                        >
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="form-control"
                            style={{ padding: "0.5rem", fontSize: "0.875rem" }}
                          />
                        </div>
                        {passwordError && (
                          <p
                            className="text-danger mb-2"
                            style={{ fontSize: "0.75rem" }}
                          >
                            {passwordError}
                          </p>
                        )}
                        <button
                          className="btn btn-primary w-100"
                          onClick={handlePasswordUpdate}
                          style={{
                            maxWidth: "200px",
                            padding: "0.5rem 1rem",
                            fontSize: "0.875rem",
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {showAddBalanceForm && (
                  <div className="row justify-content-center">
                    <div className="col-12 text-center mb-4">
                      <div className="add-balance-form mt-3">
                        <div
                          className="input-group mb-2"
                          style={{ maxWidth: "300px", margin: "0 auto" }}
                        >
                          <input
                            type="text"
                            placeholder="Amount"
                            value={balanceAmount}
                            onChange={(e) => setBalanceAmount(e.target.value)}
                            className="form-control"
                            style={{ padding: "0.5rem", fontSize: "0.875rem" }}
                          />
                        </div>
                        {balanceError && (
                          <p
                            className="text-danger mb-2"
                            style={{ fontSize: "0.75rem" }}
                          >
                            {balanceError}
                          </p>
                        )}
                        <button
                          className="btn btn-success w-100"
                          onClick={handleAddBalance}
                          style={{
                            maxWidth: "200px",
                            padding: "0.5rem 1rem",
                            fontSize: "0.875rem",
                          }}
                        >
                          Add Balance
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Ratings Section */}
              <div className="col-12 mt-5">
                <h2 className="text-center mb-4">Pending Actions</h2>
                <div className="row justify-content-center gy-4">
                  {ratings.length > 0 ? (
                    ratings.map((rating) => (
                      <div
                        className="col-xxl-3 col-lg-4 col-md-6"
                        key={rating._id}
                      >
                        <div
                          className="rating-card glass-bg p-3 text-center"
                          style={{ backgroundColor: "#333333" }} // Replace with your desired color
                        >
                          <h5 className="mb-3">Submit Rating</h5>
                          <p>
                            <strong>Campaign:</strong> {rating.campaignTitle}
                          </p>
                          <p>
                            <strong>Amount Contributed:</strong> {rating.amount}{" "}
                            USD
                          </p>
                          <p>
                            <strong>
                              Are you satisfied with the campaign?
                            </strong>
                          </p>
                          {!rating.isSubmitted && (
                            <div>
                              <button
                                className="btn btn-primary me-2"
                                onClick={() =>
                                  handleRatingSubmit(rating.campaign, "yes")
                                }
                                disabled={submittingRating === rating._id}
                              >
                                Yes
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() =>
                                  handleRatingSubmit(rating.campaign, "no")
                                }
                                disabled={submittingRating === rating._id}
                              >
                                No
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12 text-center">
                      <p className="glass-bg p-3 mb-0 text-center">
                        No actions pending
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Contributions Section */}
              <div className="col-12 mt-5 mb-5">
                <h2 className="text-center mb-4">My Contributions</h2>
                <div className="row justify-content-center gy-4">
                  {contributionsWithCampaigns.length > 0 ? (
                    contributionsWithCampaigns.map((campaign) => (
                      <div
                        className="col-xxl-3 col-lg-4 col-md-6"
                        key={campaign._id}
                      >
                        <FundraiseCard
                          id={campaign._id}
                          image={campaign.image}
                          title={campaign.title}
                          category={campaign.tags}
                          startAt={campaign.createdAt}
                          endAt={campaign.expiryDate}
                          creator={campaign.creator.username}
                          goalAmount={campaign.goalAmount}
                          remainingSlots={campaign.remainingSlots}
                          totalFundsRaised={campaign.totalFundsRaised}
                          description={campaign.description}
                          contributionPerSlot={
                            campaign.contributionPerParticipant
                          }
                          currentStatus={campaign.currentStatus}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="col-12 text-center">
                      <p className="glass-bg p-3 mb-0 text-center">
                        There're no contributions yet
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Add a margin */}
              <div className="mb-3"></div>

              {/* My Campaigns Section */}
              <div className="col-12 mt-5 mb-5">
                <h2 className="text-center mb-4">My Campaigns</h2>
                <div className="row justify-content-center gy-4">
                  {myCampaigns.length > 0 ? (
                    myCampaigns.map((campaign) => (
                      <div
                        className="col-xxl-3 col-lg-4 col-md-6"
                        key={campaign._id}
                      >
                        <FundraiseCard
                          id={campaign._id}
                          image={campaign.image}
                          title={campaign.title}
                          category={campaign.tags}
                          startAt={campaign.createdAt}
                          endAt={campaign.expiryDate}
                          creator={campaign.creator.username}
                          goalAmount={campaign.goalAmount}
                          remainingSlots={campaign.remainingSlots}
                          totalFundsRaised={campaign.totalFundsRaised}
                          description={campaign.description}
                          contributionPerSlot={
                            campaign.contributionPerParticipant
                          }
                          currentStatus={campaign.currentStatus}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="col-12 text-center">
                      <p className="glass-bg p-3 mb-0 text-center">
                        You haven't created any campaigns yet.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="transaction-list mb-5 mt-5">
                <h2 className="text-center mt-5 mb-4">My Transactions</h2>
                {loadingTransactions ? (
                  <p>Loading transactions...</p>
                ) : transactions.length > 0 ? (
                  <table className="table table-striped mt-5">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Amount (USD)</th>
                        <th>Crypto</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction) => (
                        <tr key={transaction._id}>
                          <td>{transaction._id}</td>
                          <td>{transaction.amount}</td>
                          <td>{transaction.cryptoName}</td>
                          <td>{transaction.status}</td>
                          <td>
                            {new Date(transaction.createdAt).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center">No transactions found.</p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Profile;
