import React, { useEffect, useContext, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { fixNavbarToTop } from "../../helpers/utils";
import { appSettings } from "../../helpers/settings";
import { AuthContext } from "../../context/authContext"; // Import the AuthContext
import { getProfile } from "../../api";

function Navbar() {
  const { isAuthenticated, logout } = useContext(AuthContext); // Use the context to check authentication status and logout method
  const [user, setUser] = useState(null); // Store user profile
  const navigate = useNavigate(); // Hook for navigation

  // Fetch user profile on component mount
  useEffect(() => {
    fixNavbarToTop();

    async function fetchProfile() {
      try {
        const profile = await getProfile(); // Assuming getProfile returns a promise
        setUser(profile.data); // Set the fetched profile
        console.log(profile.data); // Debugging line
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    }

    if (isAuthenticated) {
      fetchProfile(); // Fetch profile only if user is authenticated
    }
  }, [isAuthenticated]);

  // Handle logout
  const handleLogout = () => {
    logout(); // Call logout method
    navigate("/login"); // Redirect to login page after logout
  };

  return (
    <header className="main-header fixed-top">
      <div className="container">
        <nav className="navbar w-100 navbar-expand-lg px-0 justify-content-between rounded-0 shadow-0">
          <Link className="navbar-brand" to="/">
            <img
              src={`${appSettings?.logo}`}
              alt={`${appSettings?.brandName}`}
              width={appSettings.logoWidth}
              className="img-fluid"
            />
          </Link>

          <button
            className="navbar-toggler shadow-0 p-0 border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon-el">
              <span className="btn-mobile--menu-icon"></span>
            </span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-lg-between"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto navbar-nav-custom">
              <li className="nav-item">
                <NavLink className="nav-link" to="/" end>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/campaigns">
                  Campaigns
                </NavLink>
              </li>

              <li className="nav-item">
                {isAuthenticated ? (
                  <NavLink className="nav-link" to="/create-campaign">
                    Create Campaign
                  </NavLink>
                ) : (
                  <NavLink className="nav-link" to="/login">
                    Login
                  </NavLink>
                )}
              </li>
              <li className="nav-item">
                {isAuthenticated ? (
                  <NavLink className="nav-link" to="/profile">
                    Profile
                  </NavLink>
                ) : (
                  <NavLink className="nav-link" to="/signup">
                    Sign Up
                  </NavLink>
                )}
              </li>
            </ul>

            <div className="d-flex align-items-lg-center flex-column flex-lg-row">
              {isAuthenticated && user ? (
                <div className="dropdown">
                  <Link
                    className="px-0 dropdown-toggle no-caret text-reset"
                    id="accountDropdown"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    data-bs-target="#userDropdown"
                    aria-expanded="false"
                  >
                    <div>
                      <h6 className="mb-0">{user.username}</h6>{" "}
                      {/* Display username */}
                      <p className="text-sm text-muted mb-0">
                        Balance{" "}
                        {user.balance ? `$${user.balance.toFixed(2)}` : "$0.00"}
                      </p>
                    </div>
                  </Link>

                  <ul
                    className="dropdown-menu dropdown-menu-lg-end"
                    id="userDropdown"
                  >
                    <li>
                      <NavLink
                        rel="noopener noreferrer"
                        to="/profile"
                        className="dropdown-item rounded"
                      >
                        My Account
                      </NavLink>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="btn btn-danger w-100 btn-sm text-white bg-danger border-danger mt-2 py-2"
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                <Link to="/signup" className="btn btn-primary py-2">
                  <span className="text-sm">Get Started</span>
                </Link>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
